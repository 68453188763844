import React, {lazy} from 'react';
import {Navigate} from 'react-router-dom';
import Loadable from '../layouts/full/shared/loadable/Loadable';

/* ***Layouts**** */
const BlankLayout = Loadable(lazy(() => import('../layouts/blank/BlankLayout')));

/* ****Pages***** */
const LandingPage = Loadable(lazy(() => import('../views/landingPage/LandingPage')));

const UnProtectedRoutes = [
	{
		path: '/Menu',
		element: <BlankLayout/>,
		children: [
			{
				path: '/Menu',
				element: <LandingPage/>,
			},
			{
				path: '/Menu/:kategori',
				element: <LandingPage/>,
			}
		],
	},
	{
		path: '*',
		element: <Navigate to="/Menu"/>,
	},
];

export default UnProtectedRoutes;
