import React, {lazy} from 'react';
import {Navigate} from 'react-router-dom';
import Loadable from '../layouts/full/shared/loadable/Loadable';


/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full/FullLayout')));
const ApplicationLayout = Loadable(lazy(() => import('../views/application/Home')));

/* ***Application Views**** */
const ApplicationOverview = Loadable(lazy(() => import('src/views/application/views/overview/Overview')));


const ProtectedRoutes = [
	{
		path: '/',
		element: <FullLayout/>,
		children: [
			{
				path: 'overview',
				element: <ApplicationLayout/>,
				children: [
					{
						path: '',
						element: <ApplicationOverview/>
					},
					{
						path: '*',
						element: <Navigate to="/overview"/>
					}
				]
			},
			{
				path: '',
				element: <Navigate to="/overview"/>,
			},
			{
				path: '*',
				element: <Navigate to="/overview"/>,
			},
		],
	},
	{
		path: '',
		element: <Navigate to="/"/>,
	},
	{
		path: '*',
		element: <Navigate to="/"/>,
	},
];

export default ProtectedRoutes;
