interface IAccountState {
	user?: any;
	accounts?: any;
	tenants?: any;
	selectedPortfolio?: any;
	selectedWallet?: any;
	selectedAccount?: any;
	selectedTenant?: any;
	selectedCurrency?: any;
}

const accountState: IAccountState = {
	user: null,
	accounts: null,
	tenants: null,
	selectedPortfolio: null,
	selectedWallet: null,
	selectedAccount: null,
	selectedTenant: null,
	selectedCurrency: "USD"
}

interface IAppState {
	activeMode?: string;
}

const appState: IAppState = {
	activeMode: 'light', // This can be light or dark
};

export function userReducer(state = accountState, action: any) {
	switch (action.type) {
		case 'login':
			return {
				...state,
				user: action.payload.user,
				accounts: action.payload.accounts,
				tenants: action.payload.tenants,
				selectedAccount: action.payload.accounts.length > 0 ? action.payload.accounts[0] : null,
				selectedTenant: action.payload.tenants.length > 0 ? action.payload.tenants[0] : null,
			};
		case 'setUser':
			return {...state, user: action.payload};
		case 'setAccounts':
			return {...state, accounts: action.payload};
		case 'setTenants':
			return {...state, tenants: action.payload};
		case 'setSelectedPortfolio':
			return {...state, selectedPortfolio: action.payload};
		case 'setSelectedWallet':
			return {...state, selectedWallet: action.payload};
		case 'setSelectedAccount':
			return {...state, selectedAccount: action.payload};
		case 'setSelectedTenant':
			return {...state, selectedTenant: action.payload};
		case 'setSelectedCurrency':
			return {...state, selectedCurrency: action.payload};
		case 'logout':
			return accountState;
		default:
			return state;
	}
}

export function customizerReducer(state = appState, action: any) {
	switch (action.type) {
		case 'setDarkMode':
			switch (state.activeMode) {
				case 'light':
					return {...state, activeMode: 'dark'};
				case 'dark':
					return {...state, activeMode: 'light'};
				default:
					return state;
			}
		default:
			return state;
	}
}


