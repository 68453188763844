import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {TypedUseSelectorHook, useDispatch as useAppDispatch, useSelector as useAppSelector,} from 'react-redux';
import {customizerReducer, userReducer} from "./Reducers";

/* Persist Storage */
import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session'
import {persistReducer, persistStore,} from 'redux-persist';

const persistConfig = {
	key: 'galleon-root',
	storage,
};

const sessionConfig = {
	key: 'galleon-session',
	storage: storageSession,
};

const sessionUserReducer = persistReducer(sessionConfig, userReducer)
const persistCustomizerReducer = persistReducer(persistConfig, customizerReducer);

const rootReducer = combineReducers({
	customizer: persistCustomizerReducer,
	user: sessionUserReducer,
});
export const store = configureStore({
	reducer: rootReducer,
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppState = ReturnType<typeof rootReducer>;
export const useDispatch = () => useAppDispatch<typeof store.dispatch>();
export const useSelector: TypedUseSelectorHook<RootState> = useAppSelector;
export const persistor = persistStore(store);
