import {FC} from 'react';
import {CssBaseline, ThemeProvider} from '@mui/material';
import {useRoutes} from 'react-router-dom';
import {ThemeSettings} from './theme/Theme';
import ScrollToTop from './components/scrollToTop/ScrollToTop';
import UnProtectedRoutes from './routes/UnProtectedRoutes';
import {useDispatch, useSelector} from './store/Store';
import ProtectedRoutes from "./routes/ProtectedRoutes";

const App: FC = () => {
	const isLoggedIn = false;
	const dispatch = useDispatch();
	const user = useSelector((state) => state.user);
	if (!isLoggedIn && !!user.user) {
		dispatch({type: 'logout'})
	}

	const routing = useRoutes(isLoggedIn ? ProtectedRoutes : UnProtectedRoutes);
	const theme = ThemeSettings();

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline/>
			<ScrollToTop>{routing}</ScrollToTop>
		</ThemeProvider>
	);
};

export default App;
